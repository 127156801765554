<template>
  <div class="horaires adjust_width">
    <title_cat>Vos horaires</title_cat>


    <el-form @submit.native.prevent="edit">
        
      <div :label="k" class="day" v-for="(n, k) in days" :key="k">
        
        <div class="l">
          <div class="i">{{ dayName[k] }}</div>
          <div class="right">
            <span class="txt" @click="copy = n">Copier</span>
            <span class="txt" v-if="copy" @click="days[k] = JSON.parse(JSON.stringify(copy))">Coller</span>
            <span @click="days[k] = JSON.parse(JSON.stringify(dayInit))">
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="24" height="24" viewBox="0 0 24 24"><path d="M9,3V4H4V6H5V19A2,2 0 0,0 7,21H17A2,2 0 0,0 19,19V6H20V4H15V3H9M7,6H17V19H7V6M9,8V17H11V8H9M13,8V17H15V8H13Z" /></svg>
            </span>
          </div>
<!--          <el-switch v-model="n[4]" @change="active(k)" active-text="Fermé"></el-switch>-->
        </div>
        
        <div class="h">
            
          <div class="line">
            <div class="i2">Midi</div>
            <el-time-select placeholder="Ouverture" v-model="n[0]" :picker-options="{
              start: '00:00',
              step: '00:15',
              end: '23:45',
              maxTime: n[1]
            }">
            </el-time-select>
            <el-time-select placeholder="Fermeture" v-model="n[1]" :picker-options="{
              start: '00:00',
              step: '00:15',
              end: '23:45',
              minTime: n[0],
              maxTime: n[2]
            }">
            </el-time-select>
          </div>
          <div class="line">
            <div class="i2">Soir</div>
            <el-time-select placeholder="Ouverture" v-model="n[2]" :picker-options="{
              start: '00:00',
              step: '00:15',
              end: '23:45',
              minTime: n[1],
              maxTime: n[3]
            }">
            </el-time-select>
            <el-time-select placeholder="Fermeture" v-model="n[3]" :picker-options="{
              start: '00:00',
              step: '00:15',
              end: '23:45',
              minTime: n[2]
            }">
            </el-time-select>
          </div>
        </div>



      </div>

      <div class="buttons">
        <button_plus color="orange" :rounded="true" @success="edit" :load_ok="load_ok" txt="Enregistrer"></button_plus>
      </div>

    </el-form>
    
  </div>
</template>

<script>
export default {
  name: "horaires",
  data() {
    return {
      boutique: {},
      copy: null,
      dayName: [ 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi', 'Dimanche' ],
      dayInit: [ null, null, null, null ],
      days: {
        0: [ null, null, null, null ],
        1: [ null, null, null, null ],
        2: [ null, null, null, null ],
        3: [ null, null, null, null ],
        4: [ null, null, null, null ],
        5: [ null, null, null, null ],
        6: [ null, null, null, null ],
      },
      
      load_ok: false
    }
  },
  created() {
    this.$http.get('/bo/boutique').then((response) => {
      this.boutique = response.data
      
      for(let n in this.boutique.boutique.horaires) {
        console.log(n, this.boutique[n])
        this.days[n] = this.boutique.boutique.horaires[n]
      }
      
    })
  },
  methods: {
    active(day) {
      if(this.days[day][0] === null || this.days[day][1] === null || this.days[day][2] === null || this.days[day][3] === null) {
        this.days[day][0] = '11:00'
        this.days[day][1] = '14:00'
        this.days[day][2] = '18:30'
        this.days[day][3] = '21:00'
      }
    },
    edit() {
      let self = this
      for(let n in this.days) {
        if(this.days[n][0] === null || this.days[n][1] === null) {
          this.days[n][0] = null
          this.days[n][1] = null
        }
        if(this.days[n][2] === null || this.days[n][3] === null) {
          this.days[n][2] = null
          this.days[n][3] = null
        }
      }
      
      this.$http.post('/bo/boutique/edit', {
        key: 'horaires',
        value: JSON.stringify(this.days)
      }).then(() => {
        console.log('Edit ok')
        this.$store.dispatch("boutique_bo/sync");
        this.load_ok = true
        setTimeout(() => {
         self.load_ok = false 
        })
        this.edit_etat = false
      }).catch(e => console.log(e))
    }
  }
}
</script>

<style scoped>
/*.horaires .el-form-item { display: flex; }*/
/*.horaires .el-form-item__content { width: 100%; }*/
/*.horaires .el-form-item__label { width: 100px; min-width: 100px; margin-right: 4px; text-align: left }*/
/*.horaires .el-date-editor.el-input { width: inherit; margin-right: 6px; }*/
/**/
/*.buttons { display: flex; justify-content: flex-end; margin-top: 36px; }*/

.horaires { margin-bottom: 36px; }

.day { margin-bottom: 24px; }
.day .l { display: flex; align-items: center; justify-content: space-between; margin-bottom: 12px; }
.day .right { display: flex; align-items: center; }
.day .l .txt { font-size: 14px; margin-right: 6px; border: 1px solid var(--gris-button); border-radius: 4px; padding: 2px 6px; cursor: pointer }
.day .l svg { position: relative; top: 3px; cursor: pointer }
.day .h { display: flex; flex-direction: column }
.day .i { font-size: 16px;}

.day .line { display: flex; flex-direction: column; margin-bottom: 16px; }

.day .line .i2 { width: 100px; color: var(--label); font-weight: 400; font-size: 14px; margin-bottom: 6px; }
.day .line .el-input { width: 100%; margin-bottom: 4px; }


@media only screen and (min-width: 800px) {
    /*.day { display: flex; }*/
  .day .line { flex-direction: row; align-items: center; margin-bottom: 4px; }
  .day .line .i2 { margin-bottom: 0; }
  .day .line .el-input { width: calc(100% / 2 - 50px); margin-left: 4px; margin-bottom: 0; }

  .day { width: 500px; }
  
  /*.horaires .el-form { max-width: 600px; }*/
  /*.horaires .h { width: 200px; }*/
  /*.horaires .el-date-editor.el-input { width: calc(100% - 320px); }*/
  /*.horaires .line { width: calc(100% - 320px); }*/
  /*.horaires .el-switch { margin-left: 16px; width: 100px; }*/

  }
</style>